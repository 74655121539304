import { interfaces, Container } from 'inversify';

import { ApiOptions } from './ApiOptions';
import ApiService from './Services/ApiService';
import CategoryMenuService from './Services/CategoryMenuService';
import CategoryService from './Services/CategoryService';
import ConfigurationService from './Services/ConfigurationService';
import HttpService from './Services/HttpService';
import { LocationTypeProvider } from './Services/LocationTypeProvider';
import PlaceService from './Services/PlaceService';
import SearchLocationService from './Services/SearchLocationService';
import { UserService } from './Services/UserService';
import { AuthStore } from './Stores/AuthStore';
import CategoryStore from './Stores/CategoryStore';
import FeedbackStore from './Stores/FeedbackStore';
import FilterTypeStore from './Stores/FilterTypeStore';
import HomeStore from './Stores/HomeStore';
import { MapStore } from './Stores/MapStore';
import { SearchLocationStore } from './Stores/SearchLocationStore';
import SearchPlacesStore from './Stores/SearchPlacesStore';
import SearchStore from './Stores/SearchStore';
import TopMenuService from './Services/TopMenuService';

export const container = new Container({
    defaultScope: 'Singleton',
});

class IocContainer implements IIocContainer {
    constructor() {
        this.setupCommon();
    }

    private setupCommon() {
        // services
        container.bind(HttpService).toSelf();
        container.bind(ApiService).toSelf();
        container.bind(ConfigurationService).toSelf();
        container.bind(CategoryService).toSelf();
        container.bind(PlaceService).toSelf();
        container.bind(SearchLocationService).toSelf();
        container.bind(LocationTypeProvider).toSelf();
        container.bind(UserService).toSelf();
        container.bind(CategoryMenuService).toSelf();
        container.bind(TopMenuService).toSelf();

        // stores
        container.bind(AuthStore).toSelf();
        container.bind(HomeStore).toSelf();
        container.bind(SearchStore).toSelf();
        container.bind(CategoryStore).toSelf();
        container.bind(FilterTypeStore).toSelf();
        container.bind(SearchPlacesStore).toSelf();
        container.bind(SearchLocationStore).toSelf();
        container.bind(FeedbackStore).toSelf();
        container.bind(MapStore).toSelf();

        // others
        container.bind(ApiOptions).toSelf();
    }

    public setup(callback: (container: interfaces.Container) => void) {
        callback(container);
    }

    public get<T>(serviceIdentifier: interfaces.ServiceIdentifier<T>): T {
        return container.get<T>(serviceIdentifier);
    }
}

export default new IocContainer();

export interface IIocContainer {
    get<T>(serviceIdentifier: interfaces.ServiceIdentifier<T>): T;

    setup(callback: (container: interfaces.Container) => void): void;
}
