import { Typography, Grid, Card,  CardContent, CardActions, Button, CardMedia } from '@material-ui/core'; // , CardHeader, List, ListItem, ListItemIcon, ListItemText,
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
// import { Link } from "react-router-dom";

import Page from '../Components/Layout/Page';

const MediaPageMessages = defineMessages({
    pagesMediaSectionPartnersTitle: {
        id: 'PAGES_MEDIA_TITLE',
        defaultMessage: 'Pro média',
    },
});

const MediaPage: React.FC = () => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Page currentPage="media">
            <Typography className={ classes.title } variant="h4" component="h1">
                { intl.formatMessage(MediaPageMessages.pagesMediaSectionPartnersTitle) }
            </Typography>
            <br />
            <Grid container className={ classes.root } spacing={ 2 }>
            <Grid item md={ 4 } sm={ 6 } xs={12}>
                    <Card className={ classes.card }>
                        <CardMedia
                                className={classes.media}
                                image="/assets/image-nastenka.jpg"
                                title="Tiskové zprávy"
                                component="a"
                                target="_blank"
                                href="https://drive.google.com/drive/folders/18jnsvC6OxFbgPjxJZr1kkBxyzTzZfHF4" 
                        />
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Grafika
                            </Typography>
                            <Typography color="textSecondary"  className={ classes.popis }>
                            Grafické/propagační materiály pro weby, sociální sítě, tištěné zpravodaje, nástěnky (letáček)...
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button  className={ classes.button } fullWidth={true} color="primary" variant="contained" component="a" target="_blank" 
                            href="https://drive.google.com/drive/folders/18jnsvC6OxFbgPjxJZr1kkBxyzTzZfHF4" 
                            size="small">Prohlédnout dokumenty</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={ 4 } sm={ 6 } xs={12}>
                    <Card className={ classes.card }>
                        <CardMedia
                                className={classes.media}
                                image="/assets/image-typing.jpg"
                                title="Tiskové zprávy"
                                component="a"
                                target="_blank"
                                href="https://drive.google.com/drive/folders/1FXr7kExLWd4IoFoH4Ms81kFQ2jBWqTOs" 
                        />
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Tiskové zprávy
                            </Typography>
                            <Typography color="textSecondary"  className={ classes.popis }>
                            Nabízíme vám k dispozici archiv našich oficiálních tiskových zpráv.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button  className={ classes.button } fullWidth={true} color="primary" variant="contained" component="a" target="_blank" 
                            href="https://drive.google.com/drive/folders/1FXr7kExLWd4IoFoH4Ms81kFQ2jBWqTOs" 
                            size="small">Prohlédnout dokumenty</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={ 4 } sm={ 6 } xs={12}>
                    <Card className={ classes.card }>
                        <CardMedia
                                className={classes.media}
                                image="/assets/image-photograph.jpg"
                                title="Fotografie"
                                component="a"
                                target="_blank"
                                href="https://drive.google.com/drive/folders/1GrtpaZskZUlkcx5KjPHCzHG9VDTDGpcd" 
                        />
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Fotografie
                            </Typography>
                            <Typography color="textSecondary" className={ classes.popis }>
                            Nachystali jsme ilustrační fotky/obrázky, které můžete volně využít.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button   className={ classes.button } fullWidth={true} color="primary" variant="contained" component="a" target="_blank" 
                            href="https://drive.google.com/drive/folders/1GrtpaZskZUlkcx5KjPHCzHG9VDTDGpcd" 
                            size="small">Prohlédnout obrázky</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={ 4 } sm={ 6 } xs={12}>
                    <Card className={ classes.card }>
                        <CardMedia
                                className={classes.media}
                                image="/assets/image-videos.jpg"
                                title="Videa"
                                component="a"
                                target="_blank"
                                href="https://drive.google.com/drive/folders/1D-1iBhiCODW2A551hm87csLnotL1BJKc" 
                        />
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Videa
                            </Typography>
                            <Typography color="textSecondary" className={ classes.popis }>
                            Nachystali jsme pro vás také i videa, které můžete volně použít.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button  className={ classes.button } fullWidth={true} color="primary" variant="contained" component="a" target="_blank" 
                            href="https://drive.google.com/drive/folders/1D-1iBhiCODW2A551hm87csLnotL1BJKc" 
                            size="small">Prohlédnout videa</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={ 4 } sm={ 6 } xs={12}>
                    <Card className={ classes.card }>
                        <CardMedia
                                className={classes.media}
                                image="/assets/image-logos.jpg"
                                title="Logo"
                                component="a"
                                target="_blank"
                                href="https://drive.google.com/drive/folders/1BOhc3VpzD-Xav-eYZsgY6wxik_nmPR9v" 

                        />
                        <CardContent>                            
                            <Typography variant="h5" component="h2">
                                Logo
                            </Typography>
                            <Typography color="textSecondary" className={ classes.popis }>
                            Potřebujete naše logo? Stáhněte si varianty loga v různých formátech.
                            K dispozici máme i Logomanuál.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button  className={ classes.button }  fullWidth={true} color="primary" variant="contained" component="a" target="_blank" 
                            href="https://drive.google.com/drive/folders/1BOhc3VpzD-Xav-eYZsgY6wxik_nmPR9v" 
                            size="small">Prohlédnout soubory</Button>
                        </CardActions>
                    </Card>
                </Grid>
                
            </Grid>
            
            
            
        </Page>
    );
};

export default MediaPage;

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(3),
    },
    root: {
        
    },
    media: {
        height: '150px',
    },
    card: {
        backgroundColor: '#FAFAFA',
    },
    button: {
    },
    popis: {
        minHeight: theme.spacing(10),
    },
    img: {
        margin: theme.spacing(1),
    },
}));
