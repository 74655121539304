import { useLocation } from 'react-router';

const REGEX = /.*\/categories\/(\w+).*/gm;

export function useLocationCategoryId() {
    const location = useLocation();

    const match = Array.from(location.pathname.matchAll(REGEX));
    //console.log(match)
    return match && match[0] && match[0][1];
}
