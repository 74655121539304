import { injectable } from 'inversify';

import IocContainer from '../IocContainer';
import CategoryMenuItem from '../Models/CategoryMenuItem';

import ConfigurationService from './ConfigurationService';

@injectable()
export default class TopMenuService {
    private configurationService = IocContainer.get(ConfigurationService);

    public async getMenuItems(): Promise<CategoryMenuItem[]> {
        const config = await this.configurationService.getConfiguration();
        const { categoriesTop } = config;

        return categoriesTop;
    }
}
