import { LatLng } from '@shared/Core/Types';
import { injectable } from 'inversify';

@injectable()
class GoogleMapsHelper {
    public getNavigationLink(toLocation: LatLng, fromLocation: LatLng | null = null) {
        let query = 'dir/';
        if (fromLocation) {
            query += `${fromLocation.lat},${fromLocation.lng}`;
        }
        query += `/${toLocation.lat},${toLocation.lng}`;
        //return `https://www.google.com/maps/${query}`;
        return `https://www.google.com/maps?q=${toLocation.lat},${toLocation.lng}`;
    }
}

export default new GoogleMapsHelper();
