import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, fade } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRouteMatch, useHistory } from 'react-router';

import { useStores } from '../../../Stores/useStores';
import Loader from '../../Common/Loader';
import NavigationFab from '../../Common/NavigationFab';
import Panel from '../../Common/Panel';
import PlaceIcon from '../../Place/PlaceIcon';

import PlaceDetails from './PlaceDetails';
import PlacePhoto from './PlacePhoto';
import IconUrl from '../../../Components/Icons/IconUrl';

const PlaceDetailsPanel: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch<{
        placeId: string,
        categoryId: string,
        productId: string
     }>();
    const { layoutStore, homeStore, categoryStore } = useStores();
    const classes = useStyles({
        isMobile: layoutStore.isMobile,
        hasPhoto: !!homeStore.place?.photoUrl,
    });
    // const [] = useState<PlaceDetailsPanelState>({
    //     tabIndex: 0,
    // });

    // run on every route change
    React.useEffect(() => {
        layoutStore.resetToDefaults();

        const { placeId } = match.params;
        homeStore.setPlace(placeId);
        return () => {
            homeStore.clearPlace();
        };
    }, [history.location.key]);

    // TODO refactor this into a reusable custom hook and reuse it in CategoryResultsPanel
    React.useEffect(() => {
        const { categoryId } = match.params;
        const { productId } = match.params;
        if (categoryId) {
            categoryStore.setSelectedCategory(categoryId, productId);
        } else {
            categoryStore.clearSelectedCategory();
        }
    }, [history.location.key]);

    // const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    //    setState({ tabIndex: newValue });
    // }

    // function handleChangeIndex(index: number) {
    //    setState({ tabIndex: index });
    // }

    if (!homeStore.place) { return (<Loader />); }

    const canShowDirectionButton = homeStore.place && homeStore.activeMapLocation;
    return (
        <>
            { layoutStore.isMobile && canShowDirectionButton && (
                <NavigationFab place={ homeStore.place } fromLocation={ homeStore.activeMapLocation } />
            ) }
            <Panel>
                { homeStore.place && (
                // <SwipeableViews
                //    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                //    index={state.tabIndex}
                //    onChangeIndex={handleChangeIndex}
                // >
                //    <TabPanel value={state.tabIndex} index={0} dir={theme.direction}>
                    <>
                        <div className={ classes.header }>
                            <div className={ classes.photoHolder }>
                                { homeStore.place.photoUrl && (layoutStore.isMobile
                                    ? <PlacePhoto photoUrl={ homeStore.place.photoUrl } ratio={ 11.0 / 16 } />
                                    : <PlacePhoto photoUrl={ homeStore.place.photoUrl } ratio={ 11.5 / 16 } />
                                ) }
                                { !homeStore.place.photoUrl && (
                                    <div className={ classes.photoPlaceholder } />
                                ) }
                            </div>
                            <div className={ classes.titleHolder }>
                                <div className={ classes.titleInner }>
                                    {homeStore.place?.icon ? <IconUrl icon={homeStore.place.icon} size={52} kolo={true} /> :
                                    <PlaceIcon placeType={ homeStore.place.type } size="56px" rounded />
                                    }
                                    <div className={ classes.titleContainer }>
                                        <Typography variant="h5" component="h1" align="center" className={ classes.title }>{ homeStore.place.name }</Typography>
                                        { /* <IconButton className={ classes.feedbackButton }>
                                            <FeedbackIcon color="secondary" />
                                        </IconButton> */ }
                                        
                                    </div>
                                </div>
                                
                            </div>
                            { /* <Tabs
                  value={state.tabIndex}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
               >
                  <Tab label="Detail" />
                  <Tab label="Kategorie" />
               </Tabs> */ }
                        </div>
                        <PlaceDetails />
                    </>
                //    </TabPanel>
                //    <TabPanel value={state.tabIndex} index={1} dir={theme.direction}>
                //       Kategorie
                //    </TabPanel>
                // </SwipeableViews>
                ) }
            </Panel>
        </>
    );
};

export default observer(PlaceDetailsPanel);

const useStyles = makeStyles((theme) => createStyles({
    root: {
    },
    header: (args: any) => ({
        position: 'relative',

        // minHeight: 100,
        // backgroundColor: theme.palette.secondary.main,
        // color: 'white',

        // ...(!args.hasPhoto ? {
        //     borderWidth: 0,
        //     borderStyle: 'solid',
        //     borderBottomWidth: 1,
        //     borderBottomColor: fade(theme.palette.common.black, 0.12),
        // } : {}),
        marginBottom: theme.spacing(1),
    }),
    titleContainer: (args: any) => ({
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(1.5, 4),
        paddingRight: args.isMobile ? 0 : 16,
        // textAlign: (args.isMobile ? 'left' : 'center') as any,

        backgroundColor: 'white',
        borderTopLeftRadius: '24px',
    }),
    title: {
        textAlign: 'left',
        flexGrow: 1,
    },
    photoHolder: {
        // position: 'absolute',
        // left: 0,
        // top: 0,
        // height: '100%',
        // width: '100%',
        // zIndex: 9,
    },
    titleHolder: (args: any) => ({
        // position: 'absolute',
        // left: 0,
        // bottom: -50,
        // height: 100,
        width: '100%',
        marginTop: -50,
        // marginTop: args.hasPhoto ? -50 : 10,
        // zIndex: 10,

        // background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%)',
    }),
    titleInner: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: theme.spacing(3, 0, 0, 0.5),
        // textShadow: 'white 0 0 4px',
    },
    photoPlaceholder: (args: any) => ({
        // backgroundColor: theme.palette.secondary.main,
        height: 40,
    }),
    // feedbackButton: {
    //     marginTop: -7,
    // },
}));
