import {
    Drawer, List, ListItem, ListItemText, Divider, ListItemIcon, Icon, Button, Link
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ContactIcon from '@material-ui/icons/ContactSupport';
import FacebookIcon from '@material-ui/icons/Facebook';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SchoolIcon from '@material-ui/icons/School';
import InfoIcon from '@material-ui/icons/Info';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useHomeNavigation } from '../Hooks/useHomeNavigation';
import { useRootNavigation } from '../Hooks/useRootNavigation';
import { useStores } from '../Stores/useStores';

import AppStoreLinks from './Common/AppStoreLinks';
import CleanLink from './Common/CleanLink';
import LanguagePicker from './Common/LanguagePicker';
import FeedbackButton from './Feedback/FeedbackButton';

const MainMenu: React.FC = () => {
    const homeNavigation = useHomeNavigation();
    const rootNavigation = useRootNavigation();

    const { homeStore, appStore, layoutStore } = useStores();
    const classes = useStyles();

    const closeDrawer = () => {
        homeStore.setMainMenuOpen(false);
    };

    const onContactClicked = () => {
        homeStore.setMainMenuOpen(false);
    };

    const onPartnersClicked = () => {
        homeStore.setMainMenuOpen(false);
    };

    const onAboutClicked = () => {
        homeNavigation.navigateTo('/about');
        homeStore.setMainMenuOpen(false);
    };

    const onSearchPlacesClicked = () => {
        homeNavigation.navigateTo('/places/search');
        homeStore.setMainMenuOpen(false);
    };

    return (
        <Drawer
            open={ homeStore.mainMenuOpen }
            onClose={ closeDrawer }
        >
            <div className={ classes.root }>
                <div className={ classes.header }>
                    <img src="/assets/logo.png" height="100px" />
                </div>
                { appStore.hostApp === 'browser' && renderAppStores() }
				<Link
					component="a"
					href={ `tel:+420777176675` }>
						<div className={ classes.hotline }>hotline: <strong>+420 777 176 675</strong></div>
				</Link>
				<div style={{textAlign: "center", paddingTop: "7px"}}>
					<Link
						target='_blank'
						component="a"
						href={ 'https://www.pmi.com/markets/czech-republic/' }>
						Tuto aplikaci podpořila společnost
					<img alt="Philip Morris ČR" title="Philip Morris ČR" src={  'https://res.cloudinary.com/uklidmecesko/image/upload/c_thumb,w_120,h_70/v1585917524/logo-philipMorris.png' } />
					</Link>
				</div>
                <List>
                    <Divider />
                    <ListItem button onClick={ onSearchPlacesClicked }>
                        <ListItemIcon><SearchIcon /></ListItemIcon>
                        <ListItemText primary={ (
                            <FormattedMessage
                                id="MENU_OPTION_SEARCH_PLACE"
                                defaultMessage="Vyhledat místo"
                            />
                        ) }
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={ onAboutClicked }>
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary={ (
                            <FormattedMessage
                                id="MENU_OPTION_ABOUT"
                                defaultMessage="O aplikaci"
                            />
                        ) }
                        />
                    </ListItem>
                    <CleanLink to={ rootNavigation.getLinkTo('/webinar') }>
                        <ListItem button onClick={ onPartnersClicked }>
                            <ListItemIcon><svg width={25} height={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z" />
</svg>

</ListItemIcon>
                            <ListItemText primary={ (
                                <FormattedMessage
                                    id="MENU_OPTION_ADMINISTRACE"
                                    defaultMessage="Administrace"
                                />
                            ) }
                            />
                        </ListItem>
                    </CleanLink>
                    <CleanLink to={ rootNavigation.getLinkTo('/partners') }>
                        <ListItem button onClick={ onPartnersClicked }>
                            <ListItemIcon><Icon><img src="/assets/handshake.svg" /></Icon></ListItemIcon>
                            <ListItemText primary={ (
                                <FormattedMessage
                                    id="MENU_OPTION_PARTNERS"
                                    defaultMessage="Partneři"
                                />
                            ) }
                            />
                        </ListItem>
                    </CleanLink>
                    <CleanLink to={ rootNavigation.getLinkTo('/media') }>
                        <ListItem button onClick={ onPartnersClicked }>
                            <ListItemIcon><MenuBookIcon /></ListItemIcon>
                            <ListItemText primary={ (
                                <FormattedMessage
                                    id="MENU_OPTION_MEDIA"
                                    defaultMessage="Pro média"
                                />
                            ) }
                            />
                        </ListItem>
                    </CleanLink>
                    { !appStore.isApp && <CleanLink to={ {pathname: "https://www.uklidmecesko.cz/vyukaKAMsNIM/"}}
                            target="_blank">
                        <ListItem button onClick={ onPartnersClicked }>
                            <ListItemIcon><SchoolIcon /></ListItemIcon>
                            <ListItemText primary={ (
                                <FormattedMessage
                                    id="MENU_OPTION_VYUKA"
                                    defaultMessage="Výuková hodina"
                                />
                            ) }
                            />
                        </ListItem>
                    </CleanLink>
					}
                    <CleanLink to={ rootNavigation.getLinkTo('/contact') }>
                        <ListItem button onClick={ onContactClicked }>
                            <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
                            <ListItemText primary={ (
                                <FormattedMessage
                                    id="MENU_OPTION_CONTACT"
                                    defaultMessage="Kontakt"
                                />
                            ) }
                            />
                        </ListItem>
                    </CleanLink>

                    <Divider />
                    <ListItem>
                        { /* <ListItemIcon><LanguageIcon /></ListItemIcon> */ }
                        <ListItemText primary={ (
                            <LanguagePicker />
                        ) }
                        />
                    </ListItem>

                    <Divider />

                    { !appStore.isApp && <div className={ classes.socialLinksContainers }>
                        <Button
                            className={ classes.socialButton }
                            color="primary"
                            aria-label="facebook"
                            href="https://www.facebook.com/kamsnim.aplikace/"
                            target="_blank"
                            startIcon={ <FacebookIcon /> }
                        >
                            <FormattedMessage
                                id="MENU_SOCIAL_FACEBOOK"
                                defaultMessage="Sledujte nás na Facebooku"
                            />
                        </Button>
                        <Button
                            className={ classes.socialButton }
                            color="secondary"
                            aria-label="donate"
                            href="https://www.darujme.cz/projekt/1204573"
                            target="_blank"
                            startIcon={ <FavoriteIcon /> }
                        >
                            <FormattedMessage
                                id="MENU_DONATE"
                                defaultMessage="Podpořte projekt"
                            />
                        </Button>
                    </div>
					}
                    { !appStore.isApp && <ListItem className={ classes.feedbackContainer }>
                        <FeedbackButton />
                    </ListItem>
					}
                </List>
            </div>
        </Drawer>
    );

    function renderAppStores() {
        return (
            <div className={ classes.mobileStoresContainer }>
                <AppStoreLinks />
            </div>
        );
    }
};

export default observer(MainMenu);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(1, 0),
        // borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    feedbackContainer: {
        display: 'flex',
        margin: theme.spacing(1, 0),
        alignItems: 'flex-start',
        color: theme.palette.secondary.main,
    },
    mobileStoresContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    socialLinksContainers: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(3),
        alignItems: 'stretch',
    },
    socialButton: {
        justifyContent: 'flex-start',
        margin: theme.spacing(1, 0),
    },
	hotline: {
		textAlign: 'center',
		color: '#78be20',
		fontSize: '1.2rem',
		marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
	}
}));
