/**
 * Zkrátí webovou adresu na danou délku a přidá tři tečky, pokud byla zkrácena.
 * @param url - Původní webová adresa.
 * @param maxLength - Maximální povolená délka.
 * @returns Zkrácená adresa s tečkami na konci (pokud byla zkrácena).
 */
export const shortenUrl = (url: string, maxLength: number): string => {
    if (url.length <= maxLength) {
        return url; // Pokud je adresa kratší nebo rovna maxLength, vrátí se beze změny.
    }
    return url.slice(0, maxLength - 3) + "..."; // Zkrácení adresy a přidání tří teček.
};

// Příklad použití
//const longUrl = "https://www.example.com/some/very/long/url/that/needs/to/be/shortened";
//const shortUrl = shortenUrl(longUrl, 30); // Maximální délka je 30 znaků
//console.log(shortUrl); // Výstup: "https://www.example.com/some/ve..."
