import * as React from "react";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import IocContainer from "@shared/Core/IocContainer";
import IEnvironment, { IEnvironment_TYPE } from "@shared/Core/IEnvironment";

type Props = {
    icon: string;
    size?: number;
    kolo?: boolean;
};

export const  getIconUrl = (icon: string, kolo=false):string => {
    const environment = IocContainer.get<IEnvironment>(IEnvironment_TYPE);
    const iconUrl = environment.iconUrl;

    return `${iconUrl}${
            (kolo ? icon.replace("/icon/s/","/icon/k/") : icon)
        }`
} 

const IconUrl: React.FC<Props> = ({ icon, size=56, kolo=false }) => {
    return (
        <img src={getIconUrl(icon, kolo)} width={size} height={size} />
    );
};

export default IconUrl;


