import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
// import IocContainer from '@shared/Core/IocContainer';
import { IMapboxService_TYPE } from '@shared/Core/Services/IMapboxService';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import MapSearchResults from '../../../shared/src/Core/Models/MapSearchResults';
import { MunicipalityInfo } from '../../../shared/src/Core/Models/MunicipalityInfo';
import { PlaceInfo } from '../../../shared/src/Core/Models/PlaceInfo';
import { locationsEqual } from '../../../shared/src/Core/Utils/locationTolerance';
import { useService } from '../Hooks/useService';
import MapboxService from '../Services/MapboxService';
import { useStores } from '../Stores/useStores';

import MapControls from './MapControls';

const DEFAULT_VIEW = {
    zoom: 7,
    center: { lng: 15.5558695, lat: 49.8468949 },
};

const Map: React.FC = () => {
    const { searchStore, homeStore } = useStores();
    const [ready, setReady] = React.useState(false);
    const classes = useStyles({ ready });
    const mapboxService = useService<MapboxService>(IMapboxService_TYPE);

    React.useEffect(() => {
        initializeMap();
        return () => {
            mapboxService.destroy();
        };
    }, []);

    return (
        <>
            <div id="mapbox" className={ classes.map } />
            <div className={ classes.controls }>
                <MapControls />
            </div>
            { /* {!ready && (
        <div className={classes.loaderOverlay}></div>
      )} */ }
        </>
    );

    async function initializeMap() {

		await mapboxService.initializeMap('mapbox', DEFAULT_VIEW);

        setupReactions();

        homeStore.focusLocationsOfInterest();

        setReady(true);
    }

    function setupReactions() {
    // consume event when mapResults observable has changed
        reaction(() => searchStore.mapResults, updateMapResults, { fireImmediately: true });
        reaction(() => homeStore.place, updateSelectedPlace, { fireImmediately: true });
        reaction(() => homeStore.municipality, updateMunicipalityPlace, { fireImmediately: true });
        reaction(() => [homeStore.activeMapLocation, homeStore.mapLocationType], async () => {
            if (await mapboxService.ready()) {
                if (!homeStore.activeMapLocation) {
                    mapboxService.flyTo(DEFAULT_VIEW.center, DEFAULT_VIEW.zoom);
                    return;
                }

                switch (homeStore.mapLocationType) {
                case 'selected':
                    if (homeStore.selectedMapLocation && homeStore.userMapLocation && locationsEqual(homeStore.userMapLocation, homeStore.selectedMapLocation)) {
                        // prefer user location
                        mapboxService.clearMapLocation();
                        homeStore.focusUserLocation();
                    } else if (homeStore.municipality) {
                        // prefer municipality location
                        mapboxService.clearMapLocation();
                        homeStore.focusMunicipalityLocation();
                    } else if (homeStore.activeMapLocation) {
                        mapboxService.setSelectedPosition(homeStore.activeMapLocation);
                    }
                    break;
                default:
                    break;
                }
            }
        }, { fireImmediately: true });

        function updateMapResults(mapResults: MapSearchResults) {
            if (mapResults.places) {
                mapboxService.setPlacesMarkers(mapResults.places, false);
            }
            if (mapResults.clusters) {
                mapboxService.setClustersMarkers(mapResults.clusters);
            }
        }

        async function updateSelectedPlace(place: PlaceInfo | null) {
            if (await mapboxService.ready()) {
                mapboxService.setSelectedPlace(place);
                if (place) {
                    mapboxService.flyTo(place.location, Math.max(mapboxService.getZoom(), 16));
                }
            }
        }

        function updateMunicipalityPlace(municipality: MunicipalityInfo | null) {
            mapboxService.setMunicipality(municipality);
        }
    }
};

export default observer(Map);

const useStyles = makeStyles((theme: Theme) => createStyles({
    map: (args: any) => ({
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'whitesmoke',
        opacity: args.ready ? 1 : 0,
        transition: theme.transitions.create(['opacity'], { duration: 500 }),
    }),
    controls: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        flex: 1,
    },
    // loaderOverlay: {
    //   position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', pointerEvents: 'none',
    //   backgroundImage: `url('/assets/map-placeholder.png')`,
    //   backgroundPosition: 'center',
    //   backgroundRepeat: 'no-repeat',
    //   backgroundSize: '50%',
    //   animation: 'mapLoaderAnimation ease 2000ms infinite',
    // }
}));
