import {
    List, ListItem, ListItemIcon, ListItemText, Collapse, Typography, Divider, ListItemAvatar, Avatar, Tooltip, Link
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import DirectionsIcon from '@material-ui/icons/Directions';
import EmailIcon from '@material-ui/icons/Email';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import FeedbackIcon from '@material-ui/icons/Feedback';
import InfoIcon from '@material-ui/icons/Info';
import WebIcon from '@material-ui/icons/Language';
import ChevronRightIcon from '@material-ui/icons/OpenInNew';
import PhoneIcon from '@material-ui/icons/Phone';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';
import Container from '@shared/Core/Models/Container';
import { PlaceDetailAttribute } from '@shared/Core/Models/PlaceDetailAttribute';
import { PlaceInfo } from '@shared/Core/Models/PlaceInfo';
import { formatDistance } from '@shared/Core/Utils/Helpers';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';

import { useStores } from '../../../Stores/useStores';
import GoogleMapsHelper from '../../../utils/GoogleMapsHelper';
import { clsx } from '../../../utils/WebHelpers';
import DetailMarkdown from '../../Common/DetailMarkdown';
import Markdown from '../../Common/MarkDown';
import PlaceFeedbackButton from '../../Feedback/PlaceFeedbackButton';
import ContainerIco from '../../Icons/ContainerIco';
import { shortenUrl } from '../../../Components/Common/ShortenUrl';
import IconUrl from '../../../Components/Icons/IconUrl';
import Teplomer from '../../../Components/Common/Teplomer';

const PlaceDetailsMessages = defineMessages({
    containersWhatBelongsTitle: {
        id: 'PLACE_DETAILS_CONTAINERS_WHAT_BELONGS',
        defaultMessage: 'Co sem patří:',
    },
    containersWhatNotBelongsTitle: {
        id: 'PLACE_DETAILS_CONTAINERS_WHAT_NOT_BELONGS',
        defaultMessage: 'Co sem nepatří:',
    },
});

const PlaceDetails: React.FC = () => {
    const { homeStore, appStore } = useStores();
    
    const classes = useStyles();
    const intl = useIntl();

    function handleClick() {
        setOpenningExpanded(!openningExpanded);
    }
    //console.log("MISTO");
    const { place } = homeStore;
    //console.log(place);
    let expandHours = false;
    if (!place) {
        throw Error('No place loaded.'); 
    } else {
        expandHours = !place.open.open || false;
        //console.log("OTEVRENO: "+place.open.open);
    }
    const [openningExpanded, setOpenningExpanded] = React.useState(expandHours);

    

    const canShowDirectionButton = !!homeStore.activeMapLocation;
    const googleNavigationHref = canShowDirectionButton && GoogleMapsHelper.getNavigationLink(place.location, homeStore.activeMapLocation);

	// rozbalovani detailu nadob
	const [expandedItems, setExpandedItems] = React.useState<boolean[]>(
        (place.nadoby.length === 1 ?
         [true]
        :
         new Array(place.nadoby.length).fill(false)
        )
    );

	const handleToggle = (index: number) => {
		const newExpandedItems = [...expandedItems];
		newExpandedItems[index] = !newExpandedItems[index];
		setExpandedItems(newExpandedItems);
	};

    return (
        <List>
            { /* <ListSubheader>Kontakty a informace</ListSubheader> */ }
            { place.opening && renderOpeningHours(place) }
            
            
            { place.phone && (
				<Link
				component="a"
				href={ `tel:${place.phone}` }
				>
					<ListItem>
						<ListItemIcon>
							<PhoneIcon />
						</ListItemIcon>
						<ListItemText secondaryTypographyProps={{ component: 'div' }} primary={ place.phone } secondary={ place.contact || '' } />
					</ListItem>
				</Link>
            ) }
            { place.email && (
                <Link
                    component="a"
                    href={ `mailto:${place.email}` }
                    target="_blank"
                >
                    <ListItem button>
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText primary={ place.email } />
                    </ListItem>
                </Link>
            ) }
            { place.web && (
                <Link
                    component="a"
                    href={ place.web }
                    target="_blank"
                >
                    <ListItem button>
                        <ListItemIcon>
                            <WebIcon />
                        </ListItemIcon>
                        <ListItemText primary={ shortenUrl(place.web, 35) } />
                        <ListItemIcon className={ classes.listItemIconRight }>
                            <ChevronRightIcon color="action" />
                        </ListItemIcon>
                    </ListItem>
                </Link>
            ) }

            <Tooltip
                title={ (
                    <FormattedMessage
                        id="PLACE_DETAILS_CTA_NAVIGATE"
                        defaultMessage="Navigovat k tomuto místu"
                    />
                ) }
                TransitionComponent={ Zoom }
                enterDelay={ 1000 }
            >
                <div>
                    <Link
                        component="a"
                        href={ googleNavigationHref || window.location.href }
                        target="_blank"
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <DirectionsIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText secondaryTypographyProps={{ component: 'div' }}
                                primary={ place.address }
                                secondary={ place.distance
                                    ? (
                                        <FormattedMessage
                                            id="PLACE_DETAILS_DISTANCE"
                                            defaultMessage="Vzdáleno { distance }"
                                            values={ {
                                                distance: formatDistance(place.distance),
                                            } }
                                        />
                                    )
                                    : null }
                            />
                            <ListItemIcon className={ classes.listItemIconRight }>
                                <ChevronRightIcon color="action" />
                            </ListItemIcon>
                        </ListItem>
                    </Link>
                </div>
            </Tooltip>

            { place.comment && (
                <ListItem>
                    <ListItemIcon>
                        <WarningIcon />
                    </ListItemIcon>
                    <ListItemText className={ classes.mdDetail }>
                        <DetailMarkdown markdown={ place.comment } />
                    </ListItemText>
                </ListItem>
            ) }

            { place.restriction && (
                <ListItem>
                    <ListItemIcon>
                        <WarningIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText>
                        <DetailMarkdown markdown={ place.restriction } />
                    </ListItemText>
                </ListItem>
            ) }

            { place.notify && (
                <ListItem>
                    <ListItemIcon>
                        <WarningIcon color="error" />
                    </ListItemIcon>
                    <ListItemText className={ classes.mdDetail }>
                        <DetailMarkdown markdown={ place.notify } />
                    </ListItemText>
                </ListItem>
            ) }
            { renderAttributes(place) }
            <Divider />
            { renderContainers(place) }
            { place.md && (
                <>
                    <Divider />
                    <DetailMarkdown markdown={ place.md } />
                </>
            ) }

            <Divider />
            { /* <ListItem button>
                <ListItemIcon>
                    <FeedbackIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={ (
                    <FormattedMessage
                        id="PLACE_DETAILS_CTA_PLACE_FEEDBACK"
                        defaultMessage="Nahlásit chybné údaje"
                    />
                ) }
                />
            </ListItem> */ }

            { !appStore.isApp &&
				<ListItem>
                	<PlaceFeedbackButton place={ place } />
            	</ListItem>
			}
            <Divider />
            { place.source && (
                <ListItem>
                    <ListItemText secondaryTypographyProps={{ component: 'div' }} secondary={ (
                        <>
                            <Typography className={ classes.sourceText } variant="caption" component="span">
                                <FormattedMessage
                                    id="PLACE_DETAILS_DATA_SOURCE"
                                    defaultMessage="Zdroj: { source }"
                                    values={ {
                                        source: place.source,
                                    } }
                                />
                            </Typography>
                            { place.date && (
                                <Typography className={ classes.sourceText } variant="caption" component="span">
                                    { ' ' }
                                    <FormattedMessage
                                        id="PLACE_DETAILS_DATA_SOURCE_UPDATED"
                                        defaultMessage="(akt. { date })"
                                        values={ {
                                            date: place.date,
                                        } }
                                    />
                                </Typography>
                            ) }
                        </>
                    ) }
                    />
                </ListItem>
            ) }
        </List>
    );

    function renderOpeningHours(placeDetail: PlaceInfo) {
        const canExpand = placeDetail.opening && placeDetail.opening.length > 0;
        const hasKnownOpeningHours = placeDetail.opening && placeDetail.opening.length > 0;
        const hasOpeningDetail = !!placeDetail.open.detail;
        return (
            <>
                <ListItem button={ canExpand as any } onClick={ handleClick }>
                    <ListItemIcon>
                        <ScheduleIcon />
                    </ListItemIcon>
                    <ListItemText secondaryTypographyProps={{ component: 'div' }} primary={ (
                        <Typography component="strong">
                            { hasKnownOpeningHours
                                && (
                                    <>
                                        <span>
                                            { placeDetail.open.open
                                                ? (
                                                    <FormattedMessage
                                                        id="PLACE_DETAILS_OPENING_HOURS_OPEN"
                                                        defaultMessage="Otevřeno"
                                                    />
                                                )
                                                : (
                                                    <FormattedMessage
                                                        id="PLACE_DETAILS_OPENING_HOURS_CLOSED"
                                                        defaultMessage="Zavřeno"
                                                    />
                                                ) }
                                        </span>
                                        { hasOpeningDetail
                                        && <span> - </span> }
                                    </>
                                ) }
                            { hasOpeningDetail
                                && <span><DetailMarkdown markdown={ placeDetail.open.detail } /></span> }
                        </Typography>
                    ) }
                    />
                    { canExpand && (openningExpanded ? <ExpandLess /> : <ExpandMore />) }
                </ListItem>
                { canExpand && (
                    <Collapse in={ openningExpanded } timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            { placeDetail.opening && placeDetail.opening.map((day, i) => (
                                <ListItem key={ i } className={ classes.nested }>
                                    <ListItemText secondaryTypographyProps={{ component: 'div' }} secondary={ (
                                        <span className={ classes.openingHoursContainer }>
                                            <Typography component="span" variant="body2" className={ classes.den }>{ day.den }</Typography>

                                            { day.hours.map((hours, i) => (
                                                <Typography key={ i } component="span" variant="body2" className={ classes.hodina }>
                                                    <FormattedMessage
                                                        id="PLACE_DETAILS_OPENING_HOURS_RANGE"
                                                        defaultMessage="{ from } až { to }"
                                                        values={ {
                                                            from: hours.open,
                                                            to: hours.close,
                                                        } }
                                                    />
                                                </Typography>
                                            )) }

                                        </span>
                                    ) }
                                    />
                                </ListItem>
                            )) }
                        </List>
                    </Collapse>
                ) }
            </>
        );
    }

    function renderContainers(placeDetail: PlaceInfo) {



        // exception for covid category that should not display what belongs section
        if (placeDetail.type === 'covid' || placeDetail.type === 'voda0' || placeDetail.type === 'wc0') {
            return null;
        }

        if (!placeDetail.nadoby || placeDetail.nadoby.length === 0) {
            if (!placeDetail.category || placeDetail.category.length === 0) {
                return null;
            }
            return null;
            /* pokud neni nadoba, nezobrazim co se na miste odevzdava
            return (
                <ListItem>
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={ intl.formatMessage(PlaceDetailsMessages.containersWhatBelongsTitle) }
                        secondary={ placeDetail.category.join(', ') }
                    />
                </ListItem>
            );
            */
        }
        // ostatni - 'lekarna', 'box', 'shop', 'covid', 'rossmann','box2', 'box3', 'shop0', 'shop1', 'shop2', 'shop3','lekarna2', 'restaurant0', 'restaurant2', 'restaurant3', 'recycle2', 'reuse3', 'charity3', 'repair3', 'wc0', 'voda0', 'post0', 'post1', 'post2', 'rossmann2',
        return (
            <>
                { /* <ListSubheader>Nádoby</ListSubheader> */ }
                { placeDetail.nadoby.map((container, index) => (
                    <ListItem key={ container.nazev } className={ classes.containerItem } onClick={() => handleToggle(index)}  style={{ cursor: 'pointer'}}>
                        <ListItemAvatar  style={{ cursor: 'pointer'}} className={ classes.containerListItemAvatar } >
                            {
                                container?.icon ? <IconUrl icon={container.icon} size={52} /> :
                            (['default', 'collectionPoint', 'container', 'containerPoint', 'wastePoint', 'pneuShop', 'popelnice',
    'collectionPoint2', 'collectionPoint3','containerPointH', 'containerPoint2', 'containerPoint3', 'container1', 'container2', 'container3', 'track1', 'track2', 'track3',
    'popelnice1', 'popelnice2', 'popelnicePes1', 'popelniceInfekcni1',  'pneuShop2', 'autovraky2',  'gasStation0', 'gasStation1',
    'gasStation2', 'book3', 'compost3'].includes(placeDetail.type) ?
							<ContainerIco  color={container.color}/>
                            /* <Avatar style={{ cursor: 'pointer', backgroundColor: '#'+container.color}}  onClick={() => handleToggle(index)} alt={ container.nazev } src="/assets/container.png" /> */
                             :
                            <Avatar style={{ cursor: 'pointer', backgroundColor: '#'+container.color}}  onClick={() => handleToggle(index)} alt={ container.nazev } src="/assets/box.png" />
                            )
                        }
                        </ListItemAvatar>
                        <ListItemText
                            secondaryTypographyProps={{ component: 'div' }}
                            primary={ <Typography  style={{ cursor: 'pointer', lineHeight: 'normal'}} onClick={() => handleToggle(index)} component="div" variant="subtitle1" color="textPrimary">{ container.nazev }
								<Typography  style={{ cursor: 'pointer', float: 'right' }}>{expandedItems[index] ? <ExpandLess /> : <ExpandMore />}</Typography>
								{ container.typ
										&& (
											<Typography component="h5" variant="body2" className={ classes.lighter }>
                                                {container.typ} {(container.kod ? `(${container.kod})` : '')}

												{false && <FormattedMessage
													id="PLACE_DETAILS_CONTAINERS_TYPE"
													defaultMessage="{ type } { code }"
													values={ {
														type: container.typ,
														code: container.kod ? `(${container.kod})` : '',
													} }
												/>
                                                }
											</Typography>
										) }
								{ container.cetnost
										&& (
											<Typography component="h5" variant="body2" className={ classes.lighter }>
                                                {container.cetnost}
												{ false && <FormattedMessage
													id="PLACE_DETAILS_CONTAINERS_DISPOSAL_FREQUENCY"
													defaultMessage="{ frequency }"
													values={ {
														frequency: container.cetnost,
													} }
												/>}
											</Typography>
										) }
                                { container.mereni && container.mereni.length > 0
										&& (
											<Typography component="h5" variant="body2" className={ classes.lighter }>
                                                <div>{container.mereni?.map( item => <div className={ classes.teplomer }><Teplomer height={12} width={50} fillLevel={item.procento} /> {
                                                        (item.datetime ? " měřeno "+(new Date(item.datetime)).getUTCDate()+"."+((new Date(item.datetime)).getMonth()+1)+"."+(new Date(item.datetime)).getFullYear()+" v "+(new Date(item.datetime)).getHours()+":"+(new Date(item.datetime)).getMinutes().toString().padStart(2, '0'):"")} </div>)}
                                                </div>             												
											</Typography>
										) }
							</Typography> }
                            secondary={ (
                                <>
									<Collapse in={expandedItems[index]}  timeout="auto" unmountOnExit>
										{ false && container.typ
										&& (
											<Typography component="h5" variant="body2" className={ classes.lighter }>
												<FormattedMessage
													id="PLACE_DETAILS_CONTAINERS_TYPE"
													defaultMessage="{ type } { code }"
													values={ {
														type: container.typ,
														code: container.kod ? `(${container.kod})` : '',
													} }
												/>
											</Typography>
										) }
										{ false && container.cetnost
										&& (
											<Typography component="h5" variant="body2" className={ classes.lighter }>
												<FormattedMessage
													id="PLACE_DETAILS_CONTAINERS_DISPOSAL_FREQUENCY"
													defaultMessage="{ frequency }"
													values={ {
														frequency: container.cetnost,
													} }
												/>
											</Typography>
										) }
                                        {container.mereniSVG && container.mereniSVG.length > 0 &&
                                            <div className='w-[full] h-[75px]' dangerouslySetInnerHTML={{ __html: container.mereniSVG }} />
                                        }
										{ renderWhatBelongsSection(container) }
										{ renderWhatNotBelongsSection(container) }
										{ renderRestrictionsSection(container) }
									</Collapse>
                                </>
                            ) }
                        />
                    </ListItem>
                )) }
            </>
        );

        function renderWhatBelongsSection(container: Container) {
            let content = '';
            if (container.category && container.category.length > 0 && (container.patri === undefined || container.patri === '')) {
                content = container.category.join(', ');
            } else if (container.patri && container.patri !== '') {
                content = container.patri;
            } else {
                return null;
            }
            return (
                <div className={ classes.containerSection }>
                    <Typography component="div" variant="subtitle1">
                        { intl.formatMessage(PlaceDetailsMessages.containersWhatBelongsTitle) }
                    </Typography>
                    <Markdown source={ content } />
                </div>
            );
        }

        function renderWhatNotBelongsSection(container: Container) {
            if (!container.nepatri) { return null; }
            return (
                <div className={ classes.containerSection }>
                    <Typography component="div" variant="subtitle1">
                        { intl.formatMessage(PlaceDetailsMessages.containersWhatNotBelongsTitle) }
                    </Typography>
                    <Markdown source={ container.nepatri } />
                </div>
            );
        }

        function renderRestrictionsSection(container: Container) {
            if (!container.omezeni) { return null; }
            return (
                <div className={ classes.containerSection }>
                    <Typography component="div" variant="subtitle1">
                        <FormattedMessage
                            id="PLACE_DETAILS_CONTAINERS_RESTRICTION_TITLE"
                            defaultMessage="Omezení:"
                        />
                    </Typography>
                    <Markdown source={ container.omezeni } />
                </div>
            );
        }
    }

    function renderAttributes(placeDetail: PlaceInfo) {
        if (!placeDetail.attributes || placeDetail.attributes.length === 0) {
            return null;
        }

        return placeDetail.attributes.map(renderAttribute);
    }

    function renderAttribute(attribute: PlaceDetailAttribute) {
        switch (attribute.type) {
        case 'string':
            return (
                <ListItem key={ attribute.key }>
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText secondaryTypographyProps={{ component: 'div' }}
                        primary={ attribute.name }
                        secondary={ attribute.value }
                    />
                </ListItem>
            );
        default:
            return null;
        }
    }
};

export default observer(PlaceDetails);

const useStyles = makeStyles((theme) => createStyles({
    root: {},
    nested: {
        paddingLeft: theme.spacing(9),
    },
	den: {
        minWidth: theme.spacing(13),
    },
	hodina: {
        minWidth: theme.spacing(12),
    },
    openingHoursContainer: {
        display: 'flex',
        justifyContent: 'left',
    },
    listItemIconRight: {
        justifyContent: 'flex-end',
    },
    mdDetail: {
        '& a': {
            color: '#0093db',
        },
    },
    sourceText: {
        color: '#999999',
    },
    containerItem: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    containerListItemAvatar: {
        alignSelf: 'start',
        marginRight: '0px',
        position: 'relative',
        left: '-12px'
        //backgroundColor: '#999999'
    },
    containerSection: {
        marginTop: '4px',
    },
    wrapContent: {
        overflowWrap: 'break-word',
    },
    lighter: {
        fontWeight: 'lighter',
    },
    teplomer: {
        display: 'flex',
        flexDirection: 'row',
    },
}));
